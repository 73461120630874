import * as React from "react"
import {Container, Row, Col} from 'react-bootstrap'
import Seo from "../components/seo"
import TopDarkRelative from "../components/Modules/Top/TopDarkRelative";
import Footer from "../components/Modules/FooterPage"
import AgreePage from "../components/OtherPages/InfoPages/AgreePage";

const OfferPage = () => (
  <>
    <Seo 
      title="Согласие на обработку данных Рефинжиниринг" 
      description="Согласие на обработку и передачу данных пользователя компании Рефинжиниринг."
    />

    <Container fluid>
        <Row>
            <Col>
              <TopDarkRelative />
            </Col>
        </Row>
            <Container >
              <AgreePage />
            </Container>
        <Row>
            <Col style={{padding: '0'}}>
                <Footer />
            </Col>
        </Row>
    </Container>

  </>
)

export default OfferPage
