import React from 'react';
import {useStaticQuery, graphql} from 'gatsby'
import {Container, Row, Col} from 'react-bootstrap';



const AgreePage = () => {

    const data = useStaticQuery(graphql`
  {
    allWpPage(filter: {slug: {eq: "agreement"}}) {
      nodes {
        title
        content
      }
    }
  }
`)

    const agreementTitle = data.allWpPage.nodes.map(({title}) => (<h3>{title}</h3>))
    const agreementContent = data.allWpPage.nodes.map(({content}) => (<div dangerouslySetInnerHTML={{ __html: content }} />))

    return(
        <Container>
            <Row>
                <Col>
                    {agreementTitle}
                </Col>
            </Row>
            <Row>
                <Col>
                    {agreementContent}
                </Col>
            </Row>

        </Container>
    )
}

export default AgreePage;